@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);

html {
    font-family: "Montserrat",  sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    background: transparent;
}

a:active,
a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

h1 {
    margin: 0.67em 0;
    font-size: 2em;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-size: 1em;
    font-family: monospace, monospace;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    color: inherit;
    font: inherit;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
    border: 1px solid #c0c0c0;
}

legend {
    padding: 0;
    border: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

td,
th {
    padding: 0;
}

@media print {
    * {
        background: transparent !important;
        box-shadow: none !important;
        color: #000 !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    select {
        background: #fff !important;
    }

    .navbar {
        display: none;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .btn > .caret,
    .dropup > .btn > .caret {
        border-top-color: #000 !important;
    }

    .label {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ddd !important;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    background-color: #111111;
    color: #333333;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    line-height: 1.42857143;
    font-weight: 500;
}

input,
button,
select,
textarea {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}


a {
    color: #428bca;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #2a6496;
    text-decoration: underline;
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-rounded {
    border-radius: 0;
}

.img-thumbnail {
    display: inline-block;
    padding: 4px;
    max-width: 100%;
    height: auto;
    border: 1px solid #dddddd;
    border-radius: 0;
    background-color: #111111;
    line-height: 1.42857143;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.img-circle {
    border-radius: 50%;
}

hr {
    margin-top: 22px;
    margin-bottom: 22px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: inherit;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 1.1;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    color: #999999;
    font-weight: normal;
    line-height: 1;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 22px;
    margin-bottom: 11px;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
    font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 11px;
    margin-bottom: 11px;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
    font-size: 75%;
}

h1,
.h1 {
    font-size: 41px;
}

h2,
.h2 {
    font-size: 34px;
}

h3,
.h3 {
    font-size: 28px;
}

h4,
.h4 {
    font-size: 20px;
}

h5,
.h5 {
    font-size: 16px;
}

h6,
.h6 {
    font-size: 14px;
}

p {
    margin: 0 0 30px;
}

.lead {
    margin-bottom: 22px;
    font-weight: 200;
    font-size: 18px;
    line-height: 1.4;
}

@media (min-width: 768px) {
    .lead {
        font-size: 24px;
    }
}

small,
.small {
    font-size: 85%;
}

cite {
    font-style: normal;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-grey {
    color: #999999;
}

.text-primary {
    color: #428bca;
}

a.text-primary:hover {
    color: #3071a9;
}

.text-success {
    color: #3c763d;
}

a.text-success:hover {
    color: #2b542c;
}

.text-info {
    color: #31708f;
}

a.text-info:hover {
    color: #245269;
}

.text-warning {
    color: #8a6d3b;
}

a.text-warning:hover {
    color: #66512c;
}

.text-danger {
    color: #a94442;
}

a.text-danger:hover {
    color: #843534;
}

.bg-primary {
    background-color: #428bca;
    color: #fff;
}

a.bg-primary:hover {
    background-color: #3071a9;
}

.bg-success {
    background-color: #dff0d8;
}

a.bg-success:hover {
    background-color: #c1e2b3;
}

.bg-info {
    background-color: #d9edf7;
}

a.bg-info:hover {
    background-color: #afd9ee;
}

.bg-warning {
    background-color: #fcf8e3;
}

a.bg-warning:hover {
    background-color: #f7ecb5;
}

.bg-danger {
    background-color: #f2dede;
}

a.bg-danger:hover {
    background-color: #e4b9b9;
}

.page-header {
    margin: 44px 0 22px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 11px;
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    margin-left: -5px;
    padding-left: 0;
    list-style: none;
}

.list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

dl {
    margin-top: 0;
    margin-bottom: 22px;
}

dt,
dd {
    line-height: 1.42857143;
}

dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float: left;
        clear: left;
        overflow: hidden;
        width: 160px;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dl-horizontal dd {
        margin-left: 180px;
    }
}

abbr[title],
abbr[data-original-title] {
    border-bottom: 1px dotted #999999;
    cursor: help;
}

.initialism {
    text-transform: uppercase;
    font-size: 90%;
}

blockquote {
    margin: 0 0 22px;
    padding: 11px 22px;
    border-left: 5px solid #eeeeee;
    font-size: 20px;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
    display: block;
    color: #999999;
    font-size: 80%;
    line-height: 1.42857143;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eeeeee;
    border-left: 0;
    text-align: right;
}

.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
    content: '';
}

.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
    content: '\00A0 \2014';
}

blockquote:before,
blockquote:after {
    content: "";
}

address {
    margin-bottom: 30px;
    font-style: normal;
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

code {
    padding: 2px 4px;
    border-radius: 0;
    background-color: #f9f2f4;
    color: #c7254e;
    white-space: nowrap;
    font-size: 90%;
}

kbd {
    padding: 2px 4px;
    border-radius: 0;
    background-color: #333333;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
    color: #ffffff;
    font-size: 90%;
}

pre {
    display: block;
    margin: 0 0 11px;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 0;
    background-color: #f5f5f5;
    color: #333333;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 15px;
    line-height: 1.42857143;
}

pre code {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: inherit;
    white-space: pre-wrap;
    font-size: inherit;
}

.pre-scrollable {
    overflow-y: scroll;
    max-height: 340px;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1170px;
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    min-height: 1px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
}

.col-xs-12 {
    width: 100%;
}

.col-xs-11 {
    width: 91.66666667%;
}

.col-xs-10 {
    width: 83.33333333%;
}

.col-xs-9 {
    width: 75%;
}

.col-xs-8 {
    width: 66.66666667%;
}

.col-xs-7 {
    width: 58.33333333%;
}

.col-xs-6 {
    width: 50%;
}

.col-xs-5 {
    width: 41.66666667%;
}

.col-xs-4 {
    width: 33.33333333%;
}

.col-xs-3 {
    width: 25%;
}

.col-xs-2 {
    width: 16.66666667%;
}

.col-xs-1 {
    width: 8.33333333%;
}

.col-xs-pull-12 {
    right: 100%;
}

.col-xs-pull-11 {
    right: 91.66666667%;
}

.col-xs-pull-10 {
    right: 83.33333333%;
}

.col-xs-pull-9 {
    right: 75%;
}

.col-xs-pull-8 {
    right: 66.66666667%;
}

.col-xs-pull-7 {
    right: 58.33333333%;
}

.col-xs-pull-6 {
    right: 50%;
}

.col-xs-pull-5 {
    right: 41.66666667%;
}

.col-xs-pull-4 {
    right: 33.33333333%;
}

.col-xs-pull-3 {
    right: 25%;
}

.col-xs-pull-2 {
    right: 16.66666667%;
}

.col-xs-pull-1 {
    right: 8.33333333%;
}

.col-xs-pull-0 {
    right: 0;
}

.col-xs-push-12 {
    left: 100%;
}

.col-xs-push-11 {
    left: 91.66666667%;
}

.col-xs-push-10 {
    left: 83.33333333%;
}

.col-xs-push-9 {
    left: 75%;
}

.col-xs-push-8 {
    left: 66.66666667%;
}

.col-xs-push-7 {
    left: 58.33333333%;
}

.col-xs-push-6 {
    left: 50%;
}

.col-xs-push-5 {
    left: 41.66666667%;
}

.col-xs-push-4 {
    left: 33.33333333%;
}

.col-xs-push-3 {
    left: 25%;
}

.col-xs-push-2 {
    left: 16.66666667%;
}

.col-xs-push-1 {
    left: 8.33333333%;
}

.col-xs-push-0 {
    left: 0;
}

.col-xs-offset-12 {
    margin-left: 100%;
}

.col-xs-offset-11 {
    margin-left: 91.66666667%;
}

.col-xs-offset-10 {
    margin-left: 83.33333333%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-8 {
    margin-left: 66.66666667%;
}

.col-xs-offset-7 {
    margin-left: 58.33333333%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-5 {
    margin-left: 41.66666667%;
}

.col-xs-offset-4 {
    margin-left: 33.33333333%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-2 {
    margin-left: 16.66666667%;
}

.col-xs-offset-1 {
    margin-left: 8.33333333%;
}

.col-xs-offset-0 {
    margin-left: 0;
}

@media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-11 {
        width: 91.66666667%;
    }

    .col-sm-10 {
        width: 83.33333333%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-8 {
        width: 66.66666667%;
    }

    .col-sm-7 {
        width: 58.33333333%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-5 {
        width: 41.66666667%;
    }

    .col-sm-4 {
        width: 33.33333333%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-2 {
        width: 16.66666667%;
    }

    .col-sm-1 {
        width: 8.33333333%;
    }

    .col-sm-pull-12 {
        right: 100%;
    }

    .col-sm-pull-11 {
        right: 91.66666667%;
    }

    .col-sm-pull-10 {
        right: 83.33333333%;
    }

    .col-sm-pull-9 {
        right: 75%;
    }

    .col-sm-pull-8 {
        right: 66.66666667%;
    }

    .col-sm-pull-7 {
        right: 58.33333333%;
    }

    .col-sm-pull-6 {
        right: 50%;
    }

    .col-sm-pull-5 {
        right: 41.66666667%;
    }

    .col-sm-pull-4 {
        right: 33.33333333%;
    }

    .col-sm-pull-3 {
        right: 25%;
    }

    .col-sm-pull-2 {
        right: 16.66666667%;
    }

    .col-sm-pull-1 {
        right: 8.33333333%;
    }

    .col-sm-pull-0 {
        right: 0;
    }

    .col-sm-push-12 {
        left: 100%;
    }

    .col-sm-push-11 {
        left: 91.66666667%;
    }

    .col-sm-push-10 {
        left: 83.33333333%;
    }

    .col-sm-push-9 {
        left: 75%;
    }

    .col-sm-push-8 {
        left: 66.66666667%;
    }

    .col-sm-push-7 {
        left: 58.33333333%;
    }

    .col-sm-push-6 {
        left: 50%;
    }

    .col-sm-push-5 {
        left: 41.66666667%;
    }

    .col-sm-push-4 {
        left: 33.33333333%;
    }

    .col-sm-push-3 {
        left: 25%;
    }

    .col-sm-push-2 {
        left: 16.66666667%;
    }

    .col-sm-push-1 {
        left: 8.33333333%;
    }

    .col-sm-push-0 {
        left: 0;
    }

    .col-sm-offset-12 {
        margin-left: 100%;
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-sm-offset-0 {
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-11 {
        width: 91.66666667%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-pull-12 {
        right: 100%;
    }

    .col-md-pull-11 {
        right: 91.66666667%;
    }

    .col-md-pull-10 {
        right: 83.33333333%;
    }

    .col-md-pull-9 {
        right: 75%;
    }

    .col-md-pull-8 {
        right: 66.66666667%;
    }

    .col-md-pull-7 {
        right: 58.33333333%;
    }

    .col-md-pull-6 {
        right: 50%;
    }

    .col-md-pull-5 {
        right: 41.66666667%;
    }

    .col-md-pull-4 {
        right: 33.33333333%;
    }

    .col-md-pull-3 {
        right: 25%;
    }

    .col-md-pull-2 {
        right: 16.66666667%;
    }

    .col-md-pull-1 {
        right: 8.33333333%;
    }

    .col-md-pull-0 {
        right: 0;
    }

    .col-md-push-12 {
        left: 100%;
    }

    .col-md-push-11 {
        left: 91.66666667%;
    }

    .col-md-push-10 {
        left: 83.33333333%;
    }

    .col-md-push-9 {
        left: 75%;
    }

    .col-md-push-8 {
        left: 66.66666667%;
    }

    .col-md-push-7 {
        left: 58.33333333%;
    }

    .col-md-push-6 {
        left: 50%;
    }

    .col-md-push-5 {
        left: 41.66666667%;
    }

    .col-md-push-4 {
        left: 33.33333333%;
    }

    .col-md-push-3 {
        left: 25%;
    }

    .col-md-push-2 {
        left: 16.66666667%;
    }

    .col-md-push-1 {
        left: 8.33333333%;
    }

    .col-md-push-0 {
        left: 0;
    }

    .col-md-offset-12 {
        margin-left: 100%;
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-md-offset-0 {
        margin-left: 0;
    }
}

@media (min-width: 1400px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        float: left;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-11 {
        width: 91.66666667%;
    }

    .col-lg-10 {
        width: 83.33333333%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-8 {
        width: 66.66666667%;
    }

    .col-lg-7 {
        width: 58.33333333%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-5 {
        width: 41.66666667%;
    }

    .col-lg-4 {
        width: 33.33333333%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-2 {
        width: 16.66666667%;
    }

    .col-lg-1 {
        width: 8.33333333%;
    }

    .col-lg-pull-12 {
        right: 100%;
    }

    .col-lg-pull-11 {
        right: 91.66666667%;
    }

    .col-lg-pull-10 {
        right: 83.33333333%;
    }

    .col-lg-pull-9 {
        right: 75%;
    }

    .col-lg-pull-8 {
        right: 66.66666667%;
    }

    .col-lg-pull-7 {
        right: 58.33333333%;
    }

    .col-lg-pull-6 {
        right: 50%;
    }

    .col-lg-pull-5 {
        right: 41.66666667%;
    }

    .col-lg-pull-4 {
        right: 33.33333333%;
    }

    .col-lg-pull-3 {
        right: 25%;
    }

    .col-lg-pull-2 {
        right: 16.66666667%;
    }

    .col-lg-pull-1 {
        right: 8.33333333%;
    }

    .col-lg-pull-0 {
        right: 0;
    }

    .col-lg-push-12 {
        left: 100%;
    }

    .col-lg-push-11 {
        left: 91.66666667%;
    }

    .col-lg-push-10 {
        left: 83.33333333%;
    }

    .col-lg-push-9 {
        left: 75%;
    }

    .col-lg-push-8 {
        left: 66.66666667%;
    }

    .col-lg-push-7 {
        left: 58.33333333%;
    }

    .col-lg-push-6 {
        left: 50%;
    }

    .col-lg-push-5 {
        left: 41.66666667%;
    }

    .col-lg-push-4 {
        left: 33.33333333%;
    }

    .col-lg-push-3 {
        left: 25%;
    }

    .col-lg-push-2 {
        left: 16.66666667%;
    }

    .col-lg-push-1 {
        left: 8.33333333%;
    }

    .col-lg-push-0 {
        left: 0;
    }

    .col-lg-offset-12 {
        margin-left: 100%;
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }
}

table {
    max-width: 100%;
    background-color: transparent;
}

th {
    text-align: left;
}

.table {
    margin-bottom: 22px;
    width: 100%;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px;
    border-top: 1px solid #dddddd;
    vertical-align: top;
    line-height: 1.42857143;
}

.table > thead > tr > th {
    border-bottom: 2px solid #dddddd;
    vertical-align: bottom;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
    border-top: 0;
}

.table > tbody + tbody {
    border-top: 2px solid #dddddd;
}

.table .table {
    background-color: #111111;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
    padding: 5px;
}

.table-bordered {
    border: 1px solid #dddddd;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid #dddddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    background-color: #f5f5f5;
}

table col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
    background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th {
    background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
    background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th {
    background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
    background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th {
    background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
    background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th {
    background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
    background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th {
    background-color: #ebcccc;
}

@media (max-width: 767px) {
    .table-responsive {
        overflow-x: scroll;
        overflow-y: hidden;
        margin-bottom: 16px;
        width: 100%;
        border: 1px solid #dddddd;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive > .table {
        margin-bottom: 0;
    }

    .table-responsive > .table > thead > tr > th,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tfoot > tr > td {
        white-space: nowrap;
    }

    .table-responsive > .table-bordered {
        border: 0;
    }

    .table-responsive > .table-bordered > thead > tr > th:first-child,
    .table-responsive > .table-bordered > tbody > tr > th:first-child,
    .table-responsive > .table-bordered > tfoot > tr > th:first-child,
    .table-responsive > .table-bordered > thead > tr > td:first-child,
    .table-responsive > .table-bordered > tbody > tr > td:first-child,
    .table-responsive > .table-bordered > tfoot > tr > td:first-child {
        border-left: 0;
    }

    .table-responsive > .table-bordered > thead > tr > th:last-child,
    .table-responsive > .table-bordered > tbody > tr > th:last-child,
    .table-responsive > .table-bordered > tfoot > tr > th:last-child,
    .table-responsive > .table-bordered > thead > tr > td:last-child,
    .table-responsive > .table-bordered > tbody > tr > td:last-child,
    .table-responsive > .table-bordered > tfoot > tr > td:last-child {
        border-right: 0;
    }

    .table-responsive > .table-bordered > tbody > tr:last-child > th,
    .table-responsive > .table-bordered > tfoot > tr:last-child > th,
    .table-responsive > .table-bordered > tbody > tr:last-child > td,
    .table-responsive > .table-bordered > tfoot > tr:last-child > td {
        border-bottom: 0;
    }
}

fieldset {
    margin: 0;
    padding: 0;
    min-width: 0;
    border: 0;
}

legend {
    display: block;
    margin-bottom: 22px;
    padding: 0;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    color: #333333;
    font-size: 24px;
    line-height: inherit;
}

label {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="search"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    /* IE8-9 */
    line-height: normal;
}

input[type="file"] {
    display: block;
}

input[type="range"] {
    display: block;
    width: 100%;
}

select[multiple],
select[size] {
    height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

output {
    display: block;
    padding-top: 9px;
    color: #555555;
    font-size: 16px;
    line-height: 1.42857143;
}

.form-control {
    display: block;
    padding: 8px 15px;
    width: 100%;
    height: 40px;
    border: 1px solid #cccccc;
    border-radius: 0;
    background: #ffffff none;
    color: #555555;
    font-size: 16px;
    line-height: 1.42857143;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    outline: 0;
    border-color: #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
    color: #999999;
    font-weight: 300;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999999;
    font-weight: 300;
}

.form-control::-webkit-input-placeholder {
    color: #999999;
    font-weight: 300;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1;
    cursor: not-allowed;
}

textarea.form-control {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: none;
}

input[type="date"] {
    line-height: 40px;
}

.form-group {
    margin-bottom: 15px;
}

.radio,
.checkbox {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    min-height: 22px;
}

.radio label,
.checkbox label {
    display: inline;
    font-weight: normal;
    cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    float: left;
    margin-left: -20px;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 20px;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
.radio[disabled],
.radio-inline[disabled],
.checkbox[disabled],
.checkbox-inline[disabled],
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"],
fieldset[disabled] .radio,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
}

.input-sm {
    padding: 5px 15px;
    height: 33px;
    border-radius: 0;
    font-size: 14px;
    line-height: 1.5;
}

select.input-sm {
    height: 33px;
    line-height: 33px;
}

textarea.input-sm,
select[multiple].input-sm {
    height: auto;
}

.input-lg {
    padding: 10px 35px;
    height: 49px;
    border-radius: 0;
    font-size: 20px;
    line-height: 1.33;
}

select.input-lg {
    height: 49px;
    line-height: 49px;
}

textarea.input-lg,
select[multiple].input-lg {
    height: auto;
}

.has-feedback {
    position: relative;
}

.has-feedback .form-control {
    padding-right: 50px;
}

.has-feedback .form-control-feedback {
    position: absolute;
    top: 27px;
    right: 0;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
    color: #3c763d;
}

.has-success .form-control {
    border-color: #3c763d;
}

.has-success .form-control:focus {
    border-color: #2b542c;
}

.has-success .input-group-addon {
    border-color: #3c763d;
    background-color: #dff0d8;
    color: #3c763d;
}

.has-success .form-control-feedback {
    color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
    color: #8a6d3b;
}

.has-warning .form-control {
    border-color: #8a6d3b;
}

.has-warning .form-control:focus {
    border-color: #66512c;
}

.has-warning .input-group-addon {
    border-color: #8a6d3b;
    background-color: #fcf8e3;
    color: #8a6d3b;
}

.has-warning .form-control-feedback {
    color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
    color: #a94442;
}

.has-error .form-control {
    border-color: #a94442;
}

.has-error .form-control:focus {
    border-color: #843534;
}

.has-error .input-group-addon {
    border-color: #a94442;
    background-color: #f2dede;
    color: #a94442;
}

.has-error .form-control-feedback {
    color: #a94442;
}

.form-control-static {
    margin-bottom: 0;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}

@media (min-width: 768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .input-group > .form-control {
        width: 100%;
    }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .radio,
    .form-inline .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        vertical-align: middle;
    }

    .form-inline .radio input[type="radio"],
    .form-inline .checkbox input[type="checkbox"] {
        float: none;
        margin-left: 0;
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0;
    }
}

.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 9px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
    min-height: 31px;
}

.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
}

.form-horizontal .form-control-static {
    padding-top: 9px;
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: right;
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    top: 0;
    right: 15px;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    padding: 8px 25px;
    border: 1px solid transparent;
    border-radius: 0;
    background-image: none;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.42857143;
    cursor: pointer;
    -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    -moz-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    -o-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.btn:hover,
.btn:focus {
    border: 1px solid #ca293e;
    background-color: transparent;
    color: #ca293e;
    text-decoration: none;
}

.btn:active,
.btn.active {
    outline: 0;
    background-image: none;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.90;
    filter: alpha(opacity=90);
    pointer-events: auto;
}

.btn-default {
    border-color: #333333;
    background-color: transparent;
    color: #333333;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    border-color: #ca293e;
    background-color: transparent;
    color: #ca293e;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    border-color: #333333;
    background-color: transparent;
}

.btn-default .badge {
    background-color: #333333;
    color: transparent;
}

.btn-dark {
    border-color: #111111;
    background-color: #111111;
    color: #ffffff;
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
    border-color: #ca293e;
    background-color: transparent;
    color: #ca293e;
}

.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
    background-image: none;
}

.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
    border-color: #111111;
    background-color: #111111;
}

.btn-dark .badge {
    background-color: #ffffff;
    color: #111111;
}

.btn-color {
    border-color: #ca293e;
    background-color: #ca293e;
    color: #ffffff;
}

.btn-color:hover,
.btn-color:focus,
.btn-color:active,
.btn-color.active,
.open .dropdown-toggle.btn-color {
    border-color: #ca293e;
    background-color: transparent;
    color: #ca293e;
}

.btn-color:active,
.btn-color.active,
.open .dropdown-toggle.btn-color {
    background-image: none;
}

.btn-color.disabled,
.btn-color[disabled],
fieldset[disabled] .btn-color,
.btn-color.disabled:hover,
.btn-color[disabled]:hover,
fieldset[disabled] .btn-color:hover,
.btn-color.disabled:focus,
.btn-color[disabled]:focus,
fieldset[disabled] .btn-color:focus,
.btn-color.disabled:active,
.btn-color[disabled]:active,
fieldset[disabled] .btn-color:active,
.btn-color.disabled.active,
.btn-color[disabled].active,
fieldset[disabled] .btn-color.active {
    border-color: #ca293e;
    background-color: #ca293e;
}

.btn-color .badge {
    background-color: #ffffff;
    color: #ca293e;
}

.btn-white {
    border-color: #ffffff;
    background-color: transparent;
    color: #ffffff;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
    border-color: #ca293e;
    background-color: transparent;
    color: #ca293e;
}

.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
    background-image: none;
}

.btn-white.disabled,
.btn-white[disabled],
fieldset[disabled] .btn-white,
.btn-white.disabled:hover,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white:hover,
.btn-white.disabled:focus,
.btn-white[disabled]:focus,
fieldset[disabled] .btn-white:focus,
.btn-white.disabled:active,
.btn-white[disabled]:active,
fieldset[disabled] .btn-white:active,
.btn-white.disabled.active,
.btn-white[disabled].active,
fieldset[disabled] .btn-white.active {
    border-color: #ffffff;
    background-color: transparent;
}

.btn-white .badge {
    background-color: #ffffff;
    color: transparent;
}

.btn-link {
    border-radius: 0;
    color: #428bca;
    font-weight: normal;
    cursor: pointer;
}

.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    border-color: #ca293e;
}

.btn-link:hover,
.btn-link:focus {
    background-color: transparent;
    color: #2a6496;
    text-decoration: underline;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
    color: #999999;
    text-decoration: none;
}

.btn-lg {
    padding: 10px 35px;
    border-radius: 0;
    font-size: 20px;
    line-height: 1.33;
}

.btn-sm {
    padding: 5px 15px;
    border-radius: 0;
    font-size: 14px;
    line-height: 1.5;
}

.btn-xs {
    padding: 1px 5px;
    border-radius: 0;
    font-size: 14px;
    line-height: 1.5;
}

.btn-block {
    display: block;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

.fade.in {
    opacity: 1;
}

.collapse {
    display: none;
}

.collapse.in {
    display: block;
}

.collapsing {
    position: relative;
    overflow: hidden;
    height: 0;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
    display: table;
    content: " ";
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after {
    clear: both;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    border: 0;
    background-color: transparent;
    color: transparent;
    text-shadow: none;
    font: 0/0 a;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.affix {
    position: fixed;
}

@-ms-viewport {
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }

    table.visible-xs {
        display: table;
    }

    tr.visible-xs {
        display: table-row !important;
    }

    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }

    table.visible-sm {
        display: table;
    }

    tr.visible-sm {
        display: table-row !important;
    }

    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .visible-md {
        display: block !important;
    }

    table.visible-md {
        display: table;
    }

    tr.visible-md {
        display: table-row !important;
    }

    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 1400px) {
    .visible-lg {
        display: block !important;
    }

    table.visible-lg {
        display: table;
    }

    tr.visible-lg {
        display: table-row !important;
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1400px) {
    .hidden-lg {
        display: none !important;
    }
}

.visible-print {
    display: none !important;
}

@media print {
    .visible-print {
        display: block !important;
    }

    table.visible-print {
        display: table;
    }

    tr.visible-print {
        display: table-row !important;
    }

    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}


@-ms-viewport {
    width: auto !important;
}

div,
section,
article,
span,
li,
a,
a:focus,
a:active,
input:focus,
textarea:focus,
input:active,
textarea:active {
    outline: transparent !important;
}

html {
    width: 100%;
    height: 100%;
    font-size: 16px;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    line-height: 1.625;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.content-wrapper {
    position: relative;
    min-height: 100%;
    height: auto;
}

a:hover,
a:focus {
    outline: transparent !important;
    text-decoration: none;
}

ul {
    padding: 0;
}

li {
    list-style-type: none;
}

h1 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 36px;
}

h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
}

h3 {
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 20px;
}

h4 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 18px;
}

h5 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
}

h6 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2em;
}

.intro-end .close-on-click {
    cursor: pointer;
}

.backstretch img {
    -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -ms-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px);
}

.section-scroll {
    position: relative;
    z-index: 3;
    background: #ffffff;
}

.section-space {
    display: none;
}

blockquote {
    font-style: italic;
    font-size: 16px;
}

blockquote i {
    position: absolute;
    left: 30px;
    z-index: -1;
    color: #eee;
    font-size: 40px;
}

input,
button,
textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input:focus,
button:focus,
textarea:focus {
    outline: none !important;
    border-color: inherit !important;
    box-shadow: none !important;
}

.btn-default {
    -webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    -o-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    -webkit-backface-visibility: hidden;
}

.button-transparent {
    opacity: 0 !important;
    filter: alpha(opacity=0) !important;
    zoom: 1 !important;
    -webkit-opacity: 0 !important;
    -moz-opacity: 0 !important;
}

.button-submit {
    position: relative;
    display: inline-block;
    float: right;
    width: auto !important;
}

.button-submit .button-send {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.arrow-section i {
    color: #ffffff;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.arrow-section i:hover {
    color: #ca293e;
}

.arrow-section {
    position: absolute;
    bottom: 20px;
    left: 50%;
    display: block;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
}

.arrow-section i {
    font-size: 70px;
    line-height: 50px;
}

.arrow-section.arrow1 {
    bottom: 20px;
    display: none;
}

.arrow-section.arrow2 {
    top: 45%;
    display: none;
    margin-top: 80px;
}

.dropcap {
    float: left;
    margin-right: 0.3em;
    padding: 0 10px;
    background: #111111;
    color: #ffffff;
    text-align: center;
    font-size: 2em;
    line-height: 1.5em;
}

.message-success {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background: #63C55F;
    text-align: center;
    opacity: 0;
}

.message-success i {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 30px;
    line-height: 1.5em;
}

.message-error {
    border: 1px solid #E43832 !important;
    background: #fff !important;
}

.message-error::-webkit-input-placeholder {
    color: #e43832 !important;
}

.message-error:-moz-placeholder {
    color: #e43832 !important;
}

.message-error::-moz-placeholder {
    color: #e43832 !important;
}

.message-error:-ms-input-placeholder {
    color: #e43832 !important;
}

.form-control.input-row-1 {
    height: 2.5em;
}

.form-control.input-row-2 {
    height: 6em;
}

.form-control.input-row-3 {
    height: 9.4em;
}

.form-control.input-row-4 {
    height: 12.8em;
}

.form-control.input-row-5 {
    height: 16.25em;
}

.form-control.input-row-6 {
    height: 19.7em;
}

.highlight-color {
    color: #ca293e;
    font-weight: 700;
}

.highlight-txt {
    padding: 0 2px;
    background: #111111;
    color: #ffffff;
}

.highlight-outline {
    padding: 0 2px;
    border: 1px solid #ca293e;
    color: #ca293e;
}

.section-fullscreen {
    position: relative;
    background: #111111;
}

.bg-section {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /*.lh-transition(opacity .7s);*/
    /*background-size: cover;*/
    background-position: center bottom;
}

.reservation-fullscreen {
    background-image: url('http://placehold.it/1440x900');
}

.menu-fullscreen {
    background-image: url('http://placehold.it/1440x900');
}

.blog-fullscreen {
    background-image: url('http://placehold.it/1440x900');
}

.shop-fullscreen {
    background-image: url('http://placehold.it/1440x900');
}

.section-intro {
    position: relative;
}

.pre-content {
    position: relative;
    color: #ffffff;
}

.pre-content h1 {
    position: relative;
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 32px;
}

/*.pre-content h1:after {
  position: absolute;
  content: '';
  display: block;
  height: 4px;
  background: #ffffff;
  width: 60px;
  left: 0;
  bottom: -30px;
}*/
.pre-content p {
    margin-bottom: 15px;
    font-size: 16px;
}

.section-padding {
    padding-top: 15px;
    padding-bottom: 15px;
}

.section-header {
    position: relative;
}

.section-header h2 {
    margin: 0;
    padding: 25px 15px;
    background: #111111;
    color: #ffffff;
    text-align: center;
    letter-spacing: 5px;
}

.opacity-0 {
    opacity: 0;
    filter: alpha(opacity=0);
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.opacity-10 {
    opacity: 0.1;
    filter: alpha(opacity=10);
    zoom: 1;
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
}

.opacity-20 {
    opacity: 0.2;
    filter: alpha(opacity=20);
    zoom: 1;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
}

.opacity-30 {
    opacity: 0.3;
    filter: alpha(opacity=30);
    zoom: 1;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
}

.opacity-40 {
    opacity: 0.4;
    filter: alpha(opacity=40);
    zoom: 1;
    -webkit-opacity: 0.4;
    -moz-opacity: 0.4;
}

.opacity-50 {
    opacity: 0.5;
    filter: alpha(opacity=50);
    zoom: 1;
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
}

.opacity-60 {
    opacity: 0.6;
    filter: alpha(opacity=60);
    zoom: 1;
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
}

.opacity-70 {
    opacity: 0.7;
    filter: alpha(opacity=70);
    zoom: 1;
    -webkit-opacity: 0.7;
    -moz-opacity: 0.7;
}

.opacity-80 {
    opacity: 0.8;
    filter: alpha(opacity=80);
    zoom: 1;
    -webkit-opacity: 0.8;
    -moz-opacity: 0.8;
}

.opacity-90 {
    opacity: 0.9;
    filter: alpha(opacity=90);
    zoom: 1;
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
}

.opacity-100 {
    opacity: 1;
    filter: alpha(opacity=100);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.icons {
    overflow: hidden;
}

.icons li {
    float: left;
    margin-bottom: 10px;
    width: 50%;
}

.icons li span {
    font-size: 14px;
}

.icons li i {
    margin-right: 10px;
    color: #ca293e;
    font-size: 18px;
}

.list {
    margin-left: 20px;
}

.list > li {
    list-style-type: square;
}

.list > li .sublist li {
    margin-left: 30px;
    list-style-type: circle;
}

#map {
    background-color: #e5e3df;
}

::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    -webkit-border-radius: 0;
    background-color: #eee;
}

::-webkit-scrollbar:hover {
    width: 7px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    background: #aeaeae;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
    background: #bbb;
    cursor: pointer;
}

#preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    /* change if the mask should have another color then white */
    background-color: #fff;
    /* makes sure it stays on top */
}

#status {
    position: absolute;
    top: 0;
    left: 0;
    display: table;
    width: 100%;
    /* centers the loading animation horizontally one the screen */
    height: 100%;
    /* centers the loading animation vertically one the screen */
    background: no-repeat center;
}

#status .loading-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

#status .loading-wrapper img {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 1s, -webkit-transform 3s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: opacity 1s, -moz-transform 3s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: opacity 1s, -o-transform 3s cubic-bezier(0.19, 1, 0.22, 1);
    transition: opacity 1s, -webkit-transform 3s cubic-bezier(0.19, 1, 0.22, 1), -moz-transform 3s cubic-bezier(0.19, 1, 0.22, 1), -o-transform 3s cubic-bezier(0.19, 1, 0.22, 1), transform 3s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translate(0, 30px);
    -moz-transform: translate(0, 30px);
    -ms-transform: translate(0, 30px);
    -o-transform: translate(0, 30px);
    transform: translate(0, 30px);
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

#status .loading-wrapper img.ready {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

#status-loaded {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -64px;
    width: 0;
    height: 80px;
    background: no-repeat center;
    -webkit-transition: width 0.7s;
    /* centers the loading animation horizontally one the screen */
    -moz-transition: width 0.7s;
    /* centers the loading animation vertically one the screen */
    -o-transition: width 0.7s;
    transition: width 0.7s;
    /* is width and height divided by two */
}

.ui-page-scrolling-section {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ui-page-scrolling-control {
    cursor: pointer;
}

.ui-page-scrolling-main {
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.unveil {
    background: #eee;
}

.unveil figure {
    position: relative;
    display: block;
    background: #fff;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.unveil.loaded figure {
    opacity: 1;
    filter: alpha(opacity=100);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

img.alignright {
    float: right;
    margin: .3em 0 .3em 1em;
}

img.alignleft {
    float: left;
    margin: .3em 1em .3em 0;
}

img.aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.alignright {
    float: right;
}

.alignleft {
    float: left;
}

.aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) and (max-width: 991px) {
    .pre-content h1 {
        margin-top: 30px;
    }

    .pre-content p {
        font-size: 24px;
    }
}

@media (min-width: 992px) {
    .section-intro {
        position: fixed !important;
        top: 0;
        right: 0;
        left: 0;
        display: table;
        width: 100%;
    }

    .section-space {
        display: block;
    }

    .no-intro {
        padding-top: 80px;
    }

    .no-intro .section-scroll {
        position: relative;
        top: 0;
        z-index: 3;
    }

    .section-scroll {
        z-index: 3;
        background: #ffffff;
    }

    .section-padding {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pre-content {
        position: relative;
        display: table-cell;
        visibility: visible;
        color: #ffffff;
        vertical-align: middle;
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transition: opacity 0.4s 1s, visibility 0.4s 0.4s;
        -moz-transition: opacity 0.4s 1s, visibility 0.4s 0.4s;
        -o-transition: opacity 0.4s 1s, visibility 0.4s 0.4s;
        transition: opacity 0.4s 1s, visibility 0.4s 0.4s;
        zoom: 1;
        -webkit-opacity: 1;
        -moz-opacity: 1;
    }

    .pre-content h1 {
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 64px;
    }

    /*  .pre-content h1:after {
    position: absolute;
    content: '';
    display: block;
    height: 4px;
    background: #ffffff;
    width: 60px;
    left: 0;
    bottom: -30px;
  }*/
    .pre-content p {
        font-size: 24px;
    }
}

@media (min-width: 0) and (max-width: 991px) {
    html {
        overflow: auto;
    }

    .section-intro {
        position: relative !important;
    }

    .section-intro .bg-section {
        opacity: 0.3;
        filter: alpha(opacity=30);
        zoom: 1;
        -webkit-opacity: 0.3;
        -moz-opacity: 0.3;
    }

    .mobile-height {
        height: 200px;
    }
}

@media (min-width: 992px) {
    .no-intro {
        padding-top: 80px;
    }

    .section-scroll {
        z-index: 3;
        background: #ffffff;
    }

    .section-padding {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pre-content {
        position: relative;
        display: table-cell;
        color: #ffffff;
        vertical-align: middle;
    }

    .pre-content h1 {
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 55px;
    }

    /* .pre-content h1:after {
    position: absolute;
    content: '';
    display: block;
    height: 4px;
    background: #ffffff;
    width: 60px;
    left: 0;
    bottom: -30px;
  }*/
    .pre-content p {
        font-size: 24px;
    }

    .icons li {
        width: 25%;
    }

    .simple-line-icons li {
        width: 20%;
    }
}

/* End of basic styles */
/* ------------------------------------------------------ */
/* 6. Home page */
/* ------------------------------------------------------ */
.home {
    position: fixed !important;
    z-index: 10;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #111111;
}

.home .home-fullscreen {
    width: 100%;
    background-size: cover;
}

.home .basic-info {
    position: absolute;
    bottom: 90px;
    z-index: 10;
    margin-left: 15px;
    opacity: 1;
}

.home .basic-info.static {
    position: static;
}

.home .basic-info.static div {
    margin: 15px auto;
}

.home .basic-info div {
    margin: 15px 0;
}

.home .basic-info span {
    display: block;
    color: #ffffff;
    letter-spacing: 1px;
    font-weight: 400;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.home .basic-info span a {
    color: #ffffff;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.home .basic-info span.day {
    font-weight: 700;
}

.home .basic-info .social-icon {
    display: block;
    margin: 30px 0;
}

.home .basic-info .social-icon li {
    display: inline-block;
    margin: 0 5px;
}

.home .basic-info .social-icon li a {
    padding: 5px;
    color: #ffffff;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.home-classic {
    display: table;
    width: 100%;
    height: 100%;
    color: #ffffff;
}

.home-classic .home-classic-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.home-page .to-the-top {
    display: none;
}

#home .home-parallax {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
}

#home .home-parallax .parallax-layer {
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background-position: center center;
    background-size: cover;
}

#home3 .home-fullscreen {
    position: relative;
}

#home3 .video-controls {
    right: 30px;
    bottom: 0;
    left: auto;
    overflow: hidden;
}

#home3 .video-controls div {
    float: left;
    margin-right: 10px;
}

#home3 .video-controls div i {
    color: #ffffff;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

#home3 .video-controls div:hover i {
    color: #ca293e;
}

#home3 .basic-info {
    width: 100%;
}

#home4 .basic-info .btn {
    width: 200px;
}

.mobile-basic-info {
    position: relative;
    z-index: 20;
    height: 100%;
}

.mobile-basic-info:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: #111111;
    content: '';
    opacity: 0.9;
    filter: alpha(opacity=90);
    zoom: 1;
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
}

.mobile-basic-info .back-to-top {
    display: none;
}

.mobile-basic-info #footer {
    background: transparent;
}

.home-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
    background: url('http://placehold.it/1440x900') center center;
    background-size: cover;
}

/* Home page mediaqueries */
/* ------------------------------------------------------ */
@media (min-width: 768px) {
    .home-fullscreen {
        height: 100%;
    }

    .home-fullscreen .home-fullscreen {
        height: 100%;
    }

    .home-fullscreen .basic-info {
        top: 50%;
        bottom: initial;
        margin-top: 40px;
    }

    .home {
        position: relative;
    }

    .home .social-icon li a:hover {
        color: #ca293e;
    }

    .home .basic-info {
        bottom: 0;
    }

    .home .basic-info .mobile:hover,
    .home .basic-info .email:hover {
        color: #ca293e;
    }

    .home .basic-info .mobile:hover a,
    .home .basic-info .email:hover a {
        color: #ca293e;
    }

    .home-page .to-the-top {
        display: block;
    }

    #home2 .basic-info {
        top: 50%;
        bottom: 0;
        width: 100%;
    }

    #home2 .basic-info .custom-navigation {
        position: absolute;
        right: 30px;
        bottom: 15px;
        z-index: 120;
    }

    #home2 .basic-info .custom-navigation .prev {
        margin-right: 15px;
    }

    #home2 .basic-info .custom-navigation .prev,
    #home2 .basic-info .custom-navigation .next {
        position: relative;
        bottom: 0;
        left: 0;
        display: inline-block;
        padding: 0 15px;
        width: auto;
        height: auto;
        color: #ffffff;
        font-size: 28px;
        opacity: 1;
        -webkit-transition: color 0.3s;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        transition: color 0.3s;
        pointer-events: initial;
    }

    #home2 .basic-info .custom-navigation .prev i,
    #home2 .basic-info .custom-navigation .next i {
        font-size: 24px;
    }

    #home2 .basic-info .custom-navigation .prev:hover,
    #home2 .basic-info .custom-navigation .next:hover {
        color: #ca293e;
    }

    #home2 .slides-container li:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #111111;
        content: "";
        opacity: 0.6;
    }
}

/* End of home page */
/* ------------------------------------------------------ */
/* 7. Navigation bar section */
/* ------------------------------------------------------ */
.home-page #main-navbar .main-nav {
    background: rgba(255, 255, 255, 0.9);
}

.home-page #main-navbar .main-nav .subnav {
    background: rgba(17, 17, 17, 0.9);
}

.home-page #main-navbar .main-nav .subnav.image-subnav {
    background: transparent !important;
}

.home-page #main-navbar .main-nav .subnav.image-subnav .subnav-wrapper {
    display: table;
    float: none;
    width: 100%;
    table-layout: fixed;
}

.home-page #main-navbar .main-nav .subnav.image-subnav li {
    display: table-cell;
    padding: 0;
}

body.nav-transparent #main-navbar .main-nav {
    border-bottom-color: transparent !important;
    background: transparent !important;
}

body.show-nav #main-navbar .main-nav {
    display: block;
    max-height: 1000px;
    opacity: 1 !important;
    -webkit-transition: opacity 0.3s 0.1s, max-height 0s;
    -moz-transition: opacity 0.3s 0.1s, max-height 0s;
    -o-transition: opacity 0.3s 0.1s, max-height 0s;
    transition: opacity 0.3s 0.1s, max-height 0s;
}

body.show-nav .logo .dark-logo {
    opacity: 1 !important;
}

body.show-nav .logo .light-logo {
    opacity: 0 !important;
}

body.show-nav .main-reorder {
    background-color: #eeeeee !important;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
}

body.show-nav .hide-on-nav {
    opacity: 0;
    filter: alpha(opacity=0);
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

body.show-nav.nav-transparent #main-navbar .main-nav {
    border-bottom-color: transparent;
    background: transparent;
}

body.show-nav.nav-transparent #main-navbar .main-nav a {
    color: rgba(255, 255, 255, 0.6);
}

body.show-nav.nav-transparent #main-navbar .main-nav > ul > li a {
    color: rgba(255, 255, 255, 0.6);
}

body.show-nav.nav-transparent #main-navbar .main-nav .active {
    color: #fff !important;
}

body .hide-on-nav {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

body.nav-center #main-navbar {
    top: 50% !important;
    margin-top: -40px;
}

.nav-transparent #main-navbar .main-nav > ul > li:hover > a:before {
    color: #ffffff !important;
    text-shadow: 10px 0 #ffffff, -10px 0 #ffffff !important;
}

#main-navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 0;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    -o-transition: -o-transform 0.3s;
    transition: -webkit-transform 0.3s, -moz-transform 0.3s, -o-transform 0.3s, transform 0.3s;
}

#main-navbar nav {
    position: relative;
    z-index: 2;
    min-width: 1px;
    height: 80px;
}

#main-navbar .main-reorder {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 80px;
    background: #ffffff;
    text-align: center;
    cursor: pointer;
    -webkit-transition: background-color 0.3s, -webkit-transform 0.3s;
    -moz-transition: background-color 0.3s, -moz-transform 0.3s;
    -o-transition: background-color 0.3s, -o-transform 0.3s;
    transition: background-color 0.3s, -webkit-transform 0.3s, -moz-transform 0.3s, -o-transform 0.3s, transform 0.3s;
}

#main-navbar .main-reorder a {
    color: #333333;
    font-size: 35px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

#main-navbar .main-reorder a i {
    line-height: 80px;
}

#main-navbar .main-nav {
    z-index: 1;
    overflow: hidden;
    padding-right: 80px;
    padding-left: 200px;
    max-height: 0;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    background: #ffffff;
    opacity: 0;
    -webkit-transition: opacity 0.3s, max-height 0.1s 0.3s;
    -moz-transition: opacity 0.3s, max-height 0.1s 0.3s;
    -o-transition: opacity 0.3s, max-height 0.1s 0.3s;
    transition: opacity 0.3s, max-height 0.1s 0.3s;
}

#main-navbar .main-nav:before,
#main-navbar .main-nav:after {
    display: table;
    content: " ";
}

#main-navbar .main-nav:after {
    clear: both;
}

#main-navbar .main-nav:before,
#main-navbar .main-nav:after {
    display: table;
    content: " ";
}

#main-navbar .main-nav:after {
    clear: both;
}

#main-navbar .main-nav > ul {
    right: 80px;
    float: right;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
}

#main-navbar .main-nav > ul > li {
    float: left;
    padding: 0 15px;
    height: 100%;
    vertical-align: middle;
    text-align: center;
}

#main-navbar .main-nav > ul > li .hover-subnav {
    position: relative;
}

#main-navbar .main-nav > ul > li > a {
    position: relative;
    color: #333333;
    font-weight: 600;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

#main-navbar .main-nav > ul > li > a:before {
    position: absolute;
    top: 20px;
    left: 50%;
    color: transparent;
    content: '•';
    text-shadow: 0 0 transparent;
    font-weight: 400;
    font-size: 0.7em;
    -webkit-transition: -webkit-transform 0.3s, color 0.3s, text-shadow 0.3s;
    -moz-transition: -moz-transform 0.3s, color 0.3s, text-shadow 0.3s;
    -o-transition: -o-transform 0.3s, color 0.3s, text-shadow 0.3s;
    transition: -webkit-transform 0.3s, -moz-transform 0.3s, -o-transform 0.3s, transform 0.3s, color 0.3s, text-shadow 0.3s;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    pointer-events: none;
}

#main-navbar .main-nav > ul > li a {
    position: relative;
    display: block;
    color: #333333;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 80px;
}

#main-navbar .main-nav > ul > li:hover .image-subnav li div {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: opacity 0.7s 0.4s;
    -moz-transition: opacity 0.7s 0.4s;
    -o-transition: opacity 0.7s 0.4s;
    transition: opacity 0.7s 0.4s;
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

#main-navbar .main-nav > ul > li:hover .subnav {
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0s, 0s;
    -moz-transition-delay: 0s, 0s;
    -o-transition-delay: 0s, 0s;
    transition-delay: 0s, 0s;
}

#main-navbar .main-nav > ul > li:hover .menu-img {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

#main-navbar .main-nav > ul > li:hover > a:before {
    color: #333333;
    text-shadow: 10px 0 #333333, -10px 0 #333333;
}

#main-navbar .main-nav > ul > li .active {
    color: #ca293e !important;
}

#main-navbar .main-nav .subnav-wrapper {
    position: relative;
    float: left;
}

#main-navbar .main-nav .subnav {
    position: absolute;
    left: 0;
    z-index: 10;
    display: table;
    visibility: hidden;
    float: none;
    width: 100%;
    background: #111111;
    table-layout: fixed;
    opacity: 0;
    filter: alpha(opacity=0);
    /*width: 100%; right: 0;*/
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -o-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

#main-navbar .main-nav .subnav li {
    position: relative;
    /*display: table-cell*/
    display: inline-block;
    float: none;
    padding: 0 15px;
    vertical-align: top;
}

#main-navbar .main-nav .subnav li a.menu-img {
    opacity: 0.4;
    filter: alpha(opacity=40);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    zoom: 1;
    -webkit-opacity: 0.4;
    -moz-opacity: 0.4;
}

#main-navbar .main-nav .subnav li a {
    color: #ffffff;
    font-weight: 400;
}

#main-navbar .main-nav .subnav li a:before {
    position: absolute;
    top: 20px;
    left: 50%;
    color: transparent;
    content: '•';
    text-shadow: 0 0 transparent;
    font-weight: 400;
    font-size: 0.7em;
    -webkit-transition: -webkit-transform 0.3s, color 0.3s, text-shadow 0.3s;
    -moz-transition: -moz-transform 0.3s, color 0.3s, text-shadow 0.3s;
    -o-transition: -o-transform 0.3s, color 0.3s, text-shadow 0.3s;
    transition: -webkit-transform 0.3s, -moz-transform 0.3s, -o-transform 0.3s, transform 0.3s, color 0.3s, text-shadow 0.3s;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    pointer-events: none;
}

#main-navbar .main-nav .subnav li .menu-img {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}

#main-navbar .main-nav .subnav li:hover div a {
    opacity: 1;
    filter: alpha(opacity=100);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

#main-navbar .main-nav .subnav li:hover > a:first-child:before {
    color: #ffffff;
    text-shadow: 10px 0 #ffffff, -10px 0 #ffffff;
}

#main-navbar .main-nav .subnav.show-subnav {
    opacity: 1;
    filter: alpha(opacity=100);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

#main-navbar .main-nav .subnav.image-subnav li > a {
    background: #111111;
}

#main-navbar .main-nav .subnav.image-subnav li div {
    position: relative;
    background: #111111;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.4s 0s;
    -moz-transition: opacity 0.4s 0s;
    -o-transition: opacity 0.4s 0s;
    transition: opacity 0.4s 0s;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.logo {
    position: absolute;
    z-index: 2;
    padding: 0 30px;
    height: 90px;
}

.logo .dark-logo {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.logo .light-logo {
    left: 0;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.logo a,
.logo figure {
    display: block;
    height: 100%;
}

.logo figure {
    position: relative;
}

.logo img {
    padding: 24px 0;
    max-height: 100%;
}

.nav-center#main-navbar {
    top: 50% !important;
    margin-top: -40px;
}

/* Mobile navigation */
/* ------------------------------------------------------ */
#mobile-nav {
    position: relative;
    z-index: 110;
    background: #ffffff;
    -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -ms-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px);
}

#mobile-nav .menu-header {
    margin: 0;
    padding: 0;
    height: 80px;
}

#mobile-nav .menu-header .reorder {
    line-height: 80px;
}

#mobile-nav .menu-header .reorder a {
    display: block;
    margin-right: -15px;
    width: 60px;
    text-align: center;
    font-size: 26px;
}

#mobile-nav .menu-header .logo {
    display: block;
    padding: 0;
    vertical-align: middle;
    line-height: 70px;
}

#mobile-nav #flyout-container {
    z-index: 100;
    overflow: hidden;
    width: 100%;
    height: 0;
    background: #111111;
    text-align: left;
}

#mobile-nav #flyout-container #mobile-navbar {
    margin-bottom: 0;
}

#mobile-nav #flyout-container .subnav {
    overflow: hidden;
    height: 0;
}

#mobile-nav #flyout-container .open-children {
    position: absolute;
    top: 22px;
    right: 15px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    background: #f7f7f7;
    color: #333333;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
}

#mobile-nav #flyout-container .open-children i {
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    -o-transition: -o-transform 0.3s;
    transition: -webkit-transform 0.3s, -moz-transform 0.3s, -o-transform 0.3s, transform 0.3s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

#mobile-nav #flyout-container .subnav-open .open-children i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

#mobile-nav .nav-item {
    position: relative;
}

#mobile-nav .nav-item a {
    position: relative;
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid #2b2b2b;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
}

#mobile-nav .subnav li {
    position: relative;
    background: #f7f7f7;
}

#mobile-nav .subnav li a {
    padding: 10px 45px;
    border-bottom: 1px solid #ffffff;
    color: #333333;
    font-weight: 300;
}

/* End of navigation bar section */

/* ------------------------------------------------------ */
/* 11. Menu section */
/* ------------------------------------------------------ */
.menu h2 {
    margin: 0 -15px 30px;
    padding: 25px 15px;
    background: #111111;
    color: #ffffff;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 5px;
}

.menu .menu-content .mix {
    display: none;
}

.menu .menu-content .mix .row {
    background: #ffffff;
}

.menu .menu-content .menu-item {
    position: relative;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
    min-height: 1px;
    width: 100%;
    background: #ffffff;
    text-align: center;
}

@media (min-width: 768px) {
    .menu .menu-content .menu-item {
        float: left;
        width: 100%;
    }
}

@media (min-width: 992px) {
    .menu .menu-content .menu-item {
        float: left;
        width: 50%;
    }
}

@media (min-width: 1400px) {
    .menu .menu-content .menu-item {
        float: left;
        width: 33.33333333%;
    }
}

.menu .menu-content .menu-item > a {
    position: relative;
    display: block;
    overflow: hidden;
    width: 50%;
}

.menu .menu-content .menu-item > a:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: #111111;
    content: '';
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}

.menu .menu-content .menu-item > a figure,
.menu .menu-content .menu-item > a img {
    display: block;
    width: 100%;
    height: auto;
}

.menu .menu-content .menu-item > a img {
    width: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    transition: opacity 0.4s;
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.menu .menu-content .menu-item > a img.lazyloaded {
    opacity: 1;
    filter: alpha(opacity=100);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.menu .menu-content .menu-item > a .actions {
    position: absolute;
    top: 50%;
    z-index: 10;
    margin-top: -20px;
    width: 100%;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.4s 0.1s;
    -moz-transition: -moz-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.4s 0.1s;
    -o-transition: -o-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.4s 0.1s;
    transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), -moz-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), -o-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.4s 0.1s;
    -webkit-transform: translate(0, -40px);
    -moz-transform: translate(0, -40px);
    -ms-transform: translate(0, -40px);
    -o-transform: translate(0, -40px);
    transform: translate(0, -40px);
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.menu .menu-content .menu-item > a i {
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 34px;
    line-height: 40px;
}

.menu .menu-content .menu-item > a:hover:after {
    opacity: 0.7;
    filter: alpha(opacity=70);
    zoom: 1;
    -webkit-opacity: 0.7;
    -moz-opacity: 0.7;
}

.menu .menu-content .menu-item > a:hover .actions {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.menu .item-description {
    padding: 0 0 30px;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.menu .item-description h6 {
    font-size: 16px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.menu .item-description h5 {
    margin-top: 0;
    margin-bottom: 10px;
}

.menu .item-description a {
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.menu .item-description a:hover {
    color: #ca293e;
}

.menu .item-description p {
    margin: 0 0 5px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.menu .item-description .item-price {
    color: #ca293e;
    font-weight: 600;
}

.menu .item-description .old-price {
    text-decoration: line-through;
    font-size: 12px;
    opacity: 0.5;
}

.menu .item-description .new-price {
    color: #ca293e;
}

.menu .item-description:after {
    color: #fff;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.list-category {
    position: relative;
    right: 0;
    left: 0;
    display: block;
    margin-bottom: 0;
    background: #ffffff;
    text-align: center;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

.list-category:before,
.list-category:after {
    display: table;
    content: " ";
}

.list-category:after {
    clear: both;
}

.list-category:before,
.list-category:after {
    display: table;
    content: " ";
}

.list-category:after {
    clear: both;
}

.list-category li {
    position: relative;
    display: block;
    float: left;
    width: 50%;
}

.list-category li span,
.list-category li a {
    position: relative;
    display: block;
    padding: 15px;
    color: #333333;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.list-category li span:before {
    position: absolute;
    top: 30px;
    left: 50%;
    color: transparent;
    content: '•';
    text-shadow: 0 0 transparent;
    font-weight: 400;
    font-size: 0.7em;
    -webkit-transition: -webkit-transform 0.3s, color 0.3s, text-shadow 0.3s;
    -moz-transition: -moz-transform 0.3s, color 0.3s, text-shadow 0.3s;
    -o-transition: -o-transform 0.3s, color 0.3s, text-shadow 0.3s;
    transition: -webkit-transform 0.3s, -moz-transform 0.3s, -o-transform 0.3s, transform 0.3s, color 0.3s, text-shadow 0.3s;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    pointer-events: none;
}

.list-category li span:hover:before {
    color: #333333;
    text-shadow: 10px 0 #333333, -10px 0 #333333;
}

.list-category li .active {
    font-weight: 700;
}

#second-menu .menu-content .menu-item {
    position: relative;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
    min-height: 1px;
    width: 100%;
    background: #ffffff;
}

@media (min-width: 768px) {
    #second-menu .menu-content .menu-item {
        float: left;
        width: 100%;
    }
}

@media (min-width: 992px) {
    #second-menu .menu-content .menu-item {
        float: left;
        width: 25%;
    }
}

@media (min-width: 1400px) {
    #second-menu .menu-content .menu-item {
        float: left;
        width: 16.66666667%;
    }
}

#second-menu .item-description {
    padding: 0 0 30px;
    width: 100%;
}

#second-menu .item-description h6 {
    font-size: 16px;
}

#second-menu .item-description .item-price {
    color: #ca293e;
    font-weight: 600;
}

#second-menu .item-description .old-price {
    text-decoration: line-through;
    font-size: 12px;
    opacity: 0.5;
}

#second-menu .item-description .new-price {
    color: #ca293e;
}

#third-menu .items-content {
    padding: 60px 0;
}

#third-menu .menu-item {
    display: inline-block;
    float: none;
    margin-right: -4px;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    vertical-align: top;
    text-align: left;
}

#third-menu .menu-item .menu-details {
    float: right;
    margin-left: 15px;
}

#third-menu .menu-item .item-description {
    position: relative;
    margin: 0 15px 15px;
    padding: 15px 0 0;
    border-top: 1px solid #eeeeee;
}

#third-menu .menu-item .item-description p {
    font-size: 16px;
}

.menu-list {
    padding: 0 0 30px;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.menu-list .menu-content {
    margin-top: 30px;
}

.menu-list .grid-sizer {
    width: 50%;
}

.menu-list .menu-items {
    margin-bottom: 15px;
    width: 45%;
    background: #eeeeee;
}

.menu-list h6 {
    font-size: 16px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.menu-list h5 {
    margin-top: 0;
    margin-bottom: 10px;
}

.menu-list a {
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.menu-list a:hover {
    color: #ca293e;
}

.menu-list p {
    margin: 0 0 5px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.menu-list .item-price {
    color: #ca293e;
    font-weight: 600;
}

.menu-list .old-price {
    text-decoration: line-through;
    font-size: 12px;
    opacity: 0.5;
}

.menu-list .new-price {
    color: #ca293e;
}

.menu-list:after {
    color: #fff;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.menu-list .menu-item {
    margin: 15px 0;
    padding: 0 15px;
}

.menu-list .menu-category {
    padding: 25px 15px;
    background: #111;
    color: #fff;
}

.menu-list .menu-category h2 {
    margin: 0;
}

/* Menu mediaqueries */
/* ------------------------------------------------------ */
@media (max-width: 991px) {
    .menu .menu-content .menu-item {
        margin-bottom: 30px;
    }

    .menu .menu-content .menu-item > a {
        float: left;
        width: 20%;
    }

    #menu .item-description {
        float: left;
        padding-bottom: 0;
        padding-left: 15px;
        width: 80%;
        text-align: left;
    }

    #third-menu .menu-item .item-description {
        margin: 0 0 15px;
    }
}

@media (min-width: 992px) {
    .menu h2 {
        margin-bottom: 0;
    }

    .menu .item-description p {
        font-size: 14px;
    }

    #menu .item-description {
        position: absolute;
        left: 0;
        display: block;
        padding: 0 15px;
        width: 50%;
        height: 100%;
    }

    #menu .item-description > div {
        display: table;
        width: 100%;
        height: 100%;
    }

    #menu .item-description > div > div {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    #menu .item-description:after {
        position: absolute;
        top: 50%;
        right: -30px;
        left: auto;
        z-index: 3;
        display: block;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border-top: 15px solid transparent;
        border-right: none;
        border-bottom: 15px solid transparent;
        border-left: 15px solid;
        content: '';
    }

    #second-menu .menu-item {
        display: table;
        text-align: center;
    }

    #second-menu .item-description {
        display: table-cell;
        padding: 0;
        vertical-align: middle;
        text-align: center;
    }

    .list-category {
        text-align: center;
    }

    .list-category li {
        display: inline-block;
        float: none;
        width: auto;
    }

    .list-category li span {
        padding: 20px 15px;
    }

    .list-category li span:before {
        top: 35px;
    }

    #third-menu .menu-item {
        display: inline-block;
        width: 50%;
    }

    #third-menu .menu-item:nth-child(1) .item-description,
    #third-menu .menu-item:nth-child(2) .item-description {
        border-top-color: transparent;
    }
}

@media (max-width: 991px) {
    .menu-item {
        height: auto !important;
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    #menu .menu-content .menu-item,
    #shop .menu-content .menu-item {
        padding: 0;
    }

    #menu .menu-content .menu-item > a,
    #shop .menu-content .menu-item > a {
        float: right;
    }

    #menu .menu-content .menu-item:nth-child(4n-3) > a,
    #shop .menu-content .menu-item:nth-child(4n-3) > a,
    #menu .menu-content .menu-item:nth-child(4n-2) > a,
    #shop .menu-content .menu-item:nth-child(4n-2) > a {
        float: left;
    }

    #menu .menu-content .menu-item:nth-child(4n-3) .item-description,
    #shop .menu-content .menu-item:nth-child(4n-3) .item-description,
    #menu .menu-content .menu-item:nth-child(4n-2) .item-description,
    #shop .menu-content .menu-item:nth-child(4n-2) .item-description {
        right: 0;
        left: auto;
    }

    #menu .menu-content .menu-item:nth-child(4n-3) .item-description:after,
    #shop .menu-content .menu-item:nth-child(4n-3) .item-description:after,
    #menu .menu-content .menu-item:nth-child(4n-2) .item-description:after,
    #shop .menu-content .menu-item:nth-child(4n-2) .item-description:after {
        position: absolute;
        top: 50%;
        left: -30px;
        display: block;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border-top: 15px solid transparent;
        border-right: 15px solid;
        border-bottom: 15px solid transparent;
        border-left: none;
        content: '';
    }

    #second-menu .menu-content .menu-item:nth-child(8n+2),
    #second-menu .menu-content .menu-item:nth-child(8n+4),
    #second-menu .menu-content .menu-item:nth-child(8n+5),
    #second-menu .menu-content .menu-item:nth-child(8n+7) {
        background: #eeeeee;
    }
}

@media (min-width: 1400px) {
    #menu .menu-content .menu-item,
    #shop .menu-content .menu-item {
        padding: 0;
    }

    #menu .menu-content .menu-item > a,
    #shop .menu-content .menu-item > a {
        float: right;
    }

    #menu .menu-content .menu-item:nth-child(6n-5) > a,
    #shop .menu-content .menu-item:nth-child(6n-5) > a,
    #menu .menu-content .menu-item:nth-child(6n-4) > a,
    #shop .menu-content .menu-item:nth-child(6n-4) > a,
    #menu .menu-content .menu-item:nth-child(6n-3) > a,
    #shop .menu-content .menu-item:nth-child(6n-3) > a {
        float: left;
    }

    #menu .menu-content .menu-item:nth-child(6n-5) .item-description,
    #shop .menu-content .menu-item:nth-child(6n-5) .item-description,
    #menu .menu-content .menu-item:nth-child(6n-4) .item-description,
    #shop .menu-content .menu-item:nth-child(6n-4) .item-description,
    #menu .menu-content .menu-item:nth-child(6n-3) .item-description,
    #shop .menu-content .menu-item:nth-child(6n-3) .item-description {
        right: 0;
        left: auto;
    }

    #menu .menu-content .menu-item:nth-child(6n-5) .item-description:after,
    #shop .menu-content .menu-item:nth-child(6n-5) .item-description:after,
    #menu .menu-content .menu-item:nth-child(6n-4) .item-description:after,
    #shop .menu-content .menu-item:nth-child(6n-4) .item-description:after,
    #menu .menu-content .menu-item:nth-child(6n-3) .item-description:after,
    #shop .menu-content .menu-item:nth-child(6n-3) .item-description:after {
        position: absolute;
        top: 50%;
        left: -30px;
        display: block;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border-top: 15px solid transparent;
        border-right: 15px solid;
        border-bottom: 15px solid transparent;
        border-left: none;
        content: '';
    }

    #second-menu .menu-content .menu-item:nth-child(12n+2),
    #second-menu .menu-content .menu-item:nth-child(12n+4),
    #second-menu .menu-content .menu-item:nth-child(12n+6),
    #second-menu .menu-content .menu-item:nth-child(12n+7),
    #second-menu .menu-content .menu-item:nth-child(12n+9),
    #second-menu .menu-content .menu-item:nth-child(12n+11) {
        background: #eeeeee;
    }
}

/* End of menu section */
/* ------------------------------------------------------ */
/* 12. Restaurant section */
/* ------------------------------------------------------ */
#restaurant {
    z-index: 2;
    background: #ffffff;
}

#restaurant .item {
    position: relative;
    display: table;
}

#restaurant .item .restaurant-content {
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    color: #333333;
    vertical-align: middle;
    text-align: left;
}

#restaurant .item .mobile-img img {
    width: 100%;
    height: auto;
    padding-top: 80px;
}

/*#restaurant .pre-content h1:after {
  background: #333333;
}*/
#restaurant .video-wrapper {
    position: relative !important;
    height: 300px !important;
}

#restaurant .bg-section {
    background-size: cover;
}

.page-control {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 20;
    margin-top: -100px;
    margin-bottom: 0;
    height: 200px;
    background: transparent;
}

.page-control li {
    position: relative;
    display: block;
    margin: 15px;
    width: 16px;
    height: 16px;
}

.page-control li a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #333333;
    border-radius: 50%;
    background: #ffffff;
    -webkit-transition: border 0.3s, background 0.3s;
    -moz-transition: border 0.3s, background 0.3s;
    -o-transition: border 0.3s, background 0.3s;
    transition: border 0.3s, background 0.3s;
}

.page-control li a:after {
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #111111;
    box-shadow: 0 0 1px #111111;
    content: '';
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s, visibility 0s 0.3s;
    -o-transition: opacity 0.3s, -o-transform 0.3s, visibility 0s 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s, -moz-transform 0.3s, -o-transform 0.3s, transform 0.3s, visibility 0s 0.3s;
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
    zoom: 1;
    -webkit-opacity: 0;
    -moz-opacity: 0;
}

.page-control li:hover a {
    border: 2px solid #ffffff;
    background: rgba(17, 17, 17, 0.7);
}

.page-control .ui-page-scrolling-control_active a {
    border: 2px solid #ffffff;
    background: #111111;
}

.page-control .ui-page-scrolling-control_active a:after {
    visibility: hidden;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    zoom: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
}

.video-section {
    position: relative;
    visibility: visible;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #0f0f0f;
}

.video-mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2) /*url('../img/overlay.png')*/ repeat 0 0;
}

.video-controls {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 150;
    overflow: hidden;
}

.video-controls div {
    float: left;
    margin-right: 10px;
}

.video-controls div i {
    color: #ffffff;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

.video-controls div:hover i {
    color: #ca293e;
}

#slides {
    position: absolute !important;
    top: 0;
    left: 0;
    opacity: 1 !important;
}

#slides .content {
    position: relative;
    top: 50%;
    z-index: 100;
    margin-top: -50px;
    width: 100%;
    text-align: center;
}

#slides .content h1,
#slides .content p {
    margin: auto;
    width: 70%;
}

#slides img {
    width: 100%;
    height: auto;
}

.slide-content {
    position: relative;
    z-index: 100;
    display: table-cell;
    width: 100% !important;
    vertical-align: middle;
    text-align: center;
}

.slides-text {
    position: absolute;
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.slides-text > li {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #ffffff;
}

.slides-text .slide-content-wrapper {
    position: relative;
    top: 50%;
    z-index: 101;
    margin-top: -50px;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    opacity: 0;
    -webkit-transition: opacity 1.5s linear, -webkit-transform 1.5s;
    -moz-transition: opacity 1.5s linear, -moz-transform 1.5s;
    -o-transition: opacity 1.5s linear, -o-transform 1.5s;
    transition: opacity 1.5s linear, -webkit-transform 1.5s, -moz-transform 1.5s, -o-transform 1.5s, transform 1.5s;
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    transform: translate(0, -20px);
}

.slides-text .slide-content-wrapper.current-slide {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

/* Restaurant mediaqueries */
/* ------------------------------------------------------ */
@media (max-width: 767px) {
    #restaurant .item {
        height: auto !important;
    }
}

@media (min-width: 768px) {
    #restaurant .pre-content h1 {
        margin-top: 0;
    }

    #restaurant {
        height: 100%;
        background: #111111;
    }

    #restaurant .item {
        background: #000;
    }

    #restaurant .item .restaurant-content {
        display: table-cell;
        padding-left: 100px;
        padding-right: 100px;
    }

    /*  #restaurant .pre-content h1:after {
    background: #ffffff;
  }*/
    #restaurant .video-wrapper {
        position: absolute !important;
        height: 100% !important;
    }

    #slides img {
        width: auto;
    }

    .custom-navigation .prev:hover,
    .custom-navigation .next:hover {
        color: #ca293e;
    }

    .controls-video i:hover {
        color: #ca293e;
    }

    .easing {
        -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
        -moz-transition: -moz-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: -o-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: -webkit-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -moz-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -o-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}

.fp-section {
    position: relative;
    -webkit-box-sizing: border-box;
    /* Safari<=5 Android<=3 */
    -moz-box-sizing: border-box;
    /* <=28 */
    box-sizing: border-box;
}

.fp-slide {
    float: left;
}

.fp-slide,
.fp-slidesContainer {
    display: block;
    height: 100%;
}

.fp-slides {
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 100%;
    -webkit-transition: all 0.3s ease-out;
    /* Safari<=6 Android<=4.3 */
    transition: all 0.3s ease-out;
}

.fp-section.fp-table,
.fp-slide.fp-table {
    display: table;
    width: 100%;
}

.fp-tableCell {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

.fp-slidesContainer {
    position: relative;
    float: left;
}

.fp-controlArrow {
    position: absolute;
    top: 50%;
    z-index: 4;
    margin-top: -38px;
    width: 0;
    height: 0;
    border-style: solid;
    cursor: pointer;
}

.fp-controlArrow.fp-prev {
    left: 15px;
    width: 0;
    border-width: 39px 34px 39px 0;
    border-color: transparent #fff transparent transparent;
}

.fp-controlArrow.fp-next {
    right: 15px;
    border-width: 39px 0 39px 34px;
    border-color: transparent transparent transparent #fff;
}

.fp-scrollable {
    overflow: scroll;
}

.fp-easing {
    -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: -moz-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: -o-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -moz-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), -o-transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#fp-nav {
    position: fixed;
    top: 50%;
    z-index: 100;
    margin-top: -32px;
    opacity: 1;
}

#fp-nav.right {
    right: 17px;
}

#fp-nav.left {
    left: 17px;
}

.fp-slidesNav {
    position: absolute;
    left: 50%;
    z-index: 4;
    opacity: 1;
}

.fp-slidesNav.bottom {
    bottom: 17px;
}

.fp-slidesNav.top {
    top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
    margin: 0;
    padding: 0;
}

#fp-nav li,
.fp-slidesNav li {
    position: relative;
    display: block;
    margin: 0 0 16px 0;
    width: 16px;
    height: 16px;

}

.fp-slidesNav li {
    display: inline-block;
}

#fp-nav li a,
.fp-slidesNav li a {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    cursor: pointer;

}

#fp-nav li .active span,
.fp-slidesNav .active span {
    border: 2px solid #CC1100;
    background: #fff;
}

#fp-nav span,
.fp-slidesNav span {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    width: 12px;
    height: 12px;
    border: 2px solid #474646;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    -webkit-transition: border-color 0.3s 0s, background-color 0.3s 0.1s;
    -moz-transition: border-color 0.3s 0s, background-color 0.3s 0.1s;
    -o-transition: border-color 0.3s 0s, background-color 0.3s 0.1s;
    transition: border-color 0.3s 0s, background-color 0.3s 0.1s;
}

.fp-tooltip {
    position: absolute;
    top: -2px;
    max-width: 220px;
    color: #fff;
    white-space: nowrap;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
}

.fp-tooltip.right {
    right: 20px;
}

.fp-tooltip.left {
    left: 20px;
}

/* End of restaurant section */


/* ------------------------------------------------------ */
/* 21. Animations  */
/* ------------------------------------------------------ */
.yo-anim-enabled .yo-anim {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.yo-anim-enabled .yo-anim.yo-anim-start {
    opacity: 1;
}

.yo-anim-enabled .yo-anim.yo-anim-fade.yo-anim-start {
    -webkit-animation: yo-fade 0.7s ease-in forwards;
    -moz-animation: yo-fade 0.7s ease-in forwards;
    -o-animation: yo-fade 0.7s ease-in forwards;
    animation: yo-fade 0.7s ease-in forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-fade-ltr.yo-anim-start {
    -webkit-animation: yo-ltr 0.7s ease-out forwards;
    -moz-animation: yo-ltr 0.7s ease-out forwards;
    -o-animation: yo-ltr 0.7s ease-out forwards;
    animation: yo-ltr 0.7s ease-out forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-fade-rtl.yo-anim-start {
    -webkit-animation: yo-rtl 0.7s ease-out forwards;
    -moz-animation: yo-rtl 0.7s ease-out forwards;
    -o-animation: yo-rtl 0.7s ease-out forwards;
    animation: yo-rtl 0.7s ease-out forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-fade-ttb.yo-anim-start {
    -webkit-animation: yo-ttb 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    -moz-animation: yo-ttb 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    -o-animation: yo-ttb 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    animation: yo-ttb 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-fade-btt.yo-anim-start {
    -webkit-animation: yo-btt 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    -moz-animation: yo-btt 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    -o-animation: yo-btt 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    animation: yo-btt 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-zoyo-in.yo-anim-start {
    -webkit-animation: yo-zoyo-in 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -moz-animation: yo-zoyo-in 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -o-animation: yo-zoyo-in 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: yo-zoyo-in 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-zoyo-out.yo-anim-start {
    -webkit-animation: yo-zoyo-out 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -moz-animation: yo-zoyo-out 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -o-animation: yo-zoyo-out 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: yo-zoyo-out 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-pop-out.yo-anim-start {
    -webkit-animation: yo-zoyo-in 0.4s cubic-bezier(0.17, 0.89, 0.45, 1.47) forwards;
    -moz-animation: yo-zoyo-in 0.4s cubic-bezier(0.17, 0.89, 0.45, 1.47) forwards;
    -o-animation: yo-zoyo-in 0.4s cubic-bezier(0.17, 0.89, 0.45, 1.47) forwards;
    animation: yo-zoyo-in 0.4s cubic-bezier(0.17, 0.89, 0.45, 1.47) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-spin.yo-anim-start {
    -webkit-animation: yo-spin 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -moz-animation: yo-spin 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -o-animation: yo-spin 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: yo-spin 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-spin-lt {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
}

.yo-anim-enabled .yo-anim.yo-anim-spin-lt.yo-anim-start {
    -webkit-animation: yo-spin-lt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -moz-animation: yo-spin-lt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -o-animation: yo-spin-lt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: yo-spin-lt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-spin-rt {
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -o-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

.yo-anim-enabled .yo-anim.yo-anim-spin-rt.yo-anim-start {
    -webkit-animation: yo-spin-rt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -moz-animation: yo-spin-rt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -o-animation: yo-spin-rt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: yo-spin-rt 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-flip-x.yo-anim-start {
    -webkit-animation: yo-flip-x 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -moz-animation: yo-flip-x 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -o-animation: yo-flip-x 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: yo-flip-x 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-flip-y.yo-anim-start {
    -webkit-animation: yo-flip-y 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -moz-animation: yo-flip-y 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    -o-animation: yo-flip-y 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
    animation: yo-flip-y 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.yo-anim-enabled .yo-anim.yo-anim-flip {
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    transform-origin: 50% 0;
}

.yo-anim-enabled .yo-anim.yo-anim-flip.yo-anim-start {
    -webkit-animation: yo-flip 0.7s cubic-bezier(1, 0, 0, 1) forwards;
    -moz-animation: yo-flip 0.7s cubic-bezier(1, 0, 0, 1) forwards;
    -o-animation: yo-flip 0.7s cubic-bezier(1, 0, 0, 1) forwards;
    animation: yo-flip 0.7s cubic-bezier(1, 0, 0, 1) forwards;
}

/*
 * Animation Shortcodes
 */
/* keyframes */
@-webkit-keyframes yo-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes yo-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes yo-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes yo-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes yo-ltr {
    0% {
        -webkit-transform: translate(-70px, 0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes yo-ltr {
    0% {
        -moz-transform: translate(-70px, 0);
        opacity: 0;
    }
    100% {
        -moz-transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes yo-ltr {
    0% {
        -o-transform: translate(-70px, 0);
        opacity: 0;
    }
    100% {
        -o-transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes yo-ltr {
    0% {
        transform: translate(-70px, 0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes yo-rtl {
    0% {
        -webkit-transform: translate(70px, 0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes yo-rtl {
    0% {
        -moz-transform: translate(70px, 0);
        opacity: 0;
    }
    100% {
        -moz-transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes yo-rtl {
    0% {
        -o-transform: translate(70px, 0);
        opacity: 0;
    }
    100% {
        -o-transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes yo-rtl {
    0% {
        transform: translate(70px, 0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes yo-ttb {
    0% {
        -webkit-transform: translate(0, -70px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes yo-ttb {
    0% {
        -moz-transform: translate(0, -70px);
        opacity: 0;
    }
    100% {
        -moz-transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes yo-ttb {
    0% {
        -o-transform: translate(0, -70px);
        opacity: 0;
    }
    100% {
        -o-transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes yo-ttb {
    0% {
        transform: translate(0, -70px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes yo-btt {
    0% {
        -webkit-transform: translate(0, 70px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes yo-btt {
    0% {
        -moz-transform: translate(0, 70px);
        opacity: 0;
    }
    100% {
        -moz-transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes yo-btt {
    0% {
        -o-transform: translate(0, 70px);
        opacity: 0;
    }
    100% {
        -o-transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes yo-btt {
    0% {
        transform: translate(0, 70px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes yo-zoyo-in {
    0% {
        -webkit-transform: scale(0.4, 0.4);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1, 1);
        opacity: 1;
    }
}

@-moz-keyframes yo-zoyo-in {
    0% {
        -moz-transform: scale(0.4, 0.4);
        opacity: 0;
    }
    100% {
        -moz-transform: scale(1, 1);
        opacity: 1;
    }
}

@-o-keyframes yo-zoyo-in {
    0% {
        -o-transform: scale(0.4, 0.4);
        opacity: 0;
    }
    100% {
        -o-transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes yo-zoyo-in {
    0% {
        transform: scale(0.4, 0.4);
        opacity: 0;
    }
    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@-webkit-keyframes yo-zoyo-out {
    0% {
        -webkit-transform: scale(1.4, 1.4);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1, 1);
        opacity: 1;
    }
}

@-moz-keyframes yo-zoyo-out {
    0% {
        -moz-transform: scale(1.4, 1.4);
        opacity: 0;
    }
    100% {
        -moz-transform: scale(1, 1);
        opacity: 1;
    }
}

@-o-keyframes yo-zoyo-out {
    0% {
        -o-transform: scale(1.4, 1.4);
        opacity: 0;
    }
    100% {
        -o-transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes yo-zoyo-out {
    0% {
        transform: scale(1.4, 1.4);
        opacity: 0;
    }
    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@-webkit-keyframes yo-spin {
    0% {
        -webkit-transform: rotate(0deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(360deg);
        opacity: 1;
    }
}

@-moz-keyframes yo-spin {
    0% {
        -moz-transform: rotate(0deg);
        opacity: 0;
    }
    100% {
        -moz-transform: rotate(360deg);
        opacity: 1;
    }
}

@-o-keyframes yo-spin {
    0% {
        -o-transform: rotate(0deg);
        opacity: 0;
    }
    100% {
        -o-transform: rotate(360deg);
        opacity: 1;
    }
}

@keyframes yo-spin {
    0% {
        transform: rotate(0deg);
        opacity: 0;
    }
    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}

@-webkit-keyframes yo-spin-lt {
    0% {
        -webkit-transform: rotate(90deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0deg);
        opacity: 1;
    }
}

@-moz-keyframes yo-spin-lt {
    0% {
        -moz-transform: rotate(90deg);
        opacity: 0;
    }
    100% {
        -moz-transform: rotate(0deg);
        opacity: 1;
    }
}

@-o-keyframes yo-spin-lt {
    0% {
        -o-transform: rotate(90deg);
        opacity: 0;
    }
    100% {
        -o-transform: rotate(0deg);
        opacity: 1;
    }
}

@keyframes yo-spin-lt {
    0% {
        transform: rotate(90deg);
        opacity: 0;
    }
    100% {
        transform: rotate(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes yo-spin-rt {
    0% {
        -webkit-transform: rotate(-90deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0deg);
        opacity: 1;
    }
}

@-moz-keyframes yo-spin-rt {
    0% {
        -moz-transform: rotate(-90deg);
        opacity: 0;
    }
    100% {
        -moz-transform: rotate(0deg);
        opacity: 1;
    }
}

@-o-keyframes yo-spin-rt {
    0% {
        -o-transform: rotate(-90deg);
        opacity: 0;
    }
    100% {
        -o-transform: rotate(0deg);
        opacity: 1;
    }
}

@keyframes yo-spin-rt {
    0% {
        transform: rotate(-90deg);
        opacity: 0;
    }
    100% {
        transform: rotate(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes yo-flip-x {
    0% {
        -webkit-transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

@-moz-keyframes yo-flip-x {
    0% {
        -moz-transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    100% {
        -moz-transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

@-o-keyframes yo-flip-x {
    0% {
        -o-transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    100% {
        -o-transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

@keyframes yo-flip-x {
    0% {
        transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    100% {
        transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes yo-flip-y {
    0% {
        -webkit-transform: perspective(550px) rotateY(90deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: perspective(550px) rotateY(0deg);
        opacity: 1;
    }
}

@-moz-keyframes yo-flip-y {
    0% {
        -moz-transform: perspective(550px) rotateY(90deg);
        opacity: 0;
    }
    100% {
        -moz-transform: perspective(550px) rotateY(0deg);
        opacity: 1;
    }
}

@-o-keyframes yo-flip-y {
    0% {
        -o-transform: perspective(550px) rotateY(90deg);
        opacity: 0;
    }
    100% {
        -o-transform: perspective(550px) rotateY(0deg);
        opacity: 1;
    }
}

@keyframes yo-flip-y {
    0% {
        transform: perspective(550px) rotateY(90deg);
        opacity: 0;
    }
    100% {
        transform: perspective(550px) rotateY(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes yo-flip {
    0% {
        -webkit-transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    30% {
        -webkit-transform: perspective(550px) rotateX(-35deg);
        opacity: .4;
    }
    60% {
        -webkit-transform: perspective(550px) rotateX(15deg);
        opacity: .6;
    }
    80% {
        -webkit-transform: perspective(550px) rotateX(-7deg);
        opacity: .8;
    }
    100% {
        -webkit-transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

@-moz-keyframes yo-flip {
    0% {
        -moz-transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    30% {
        -moz-transform: perspective(550px) rotateX(-35deg);
        opacity: .4;
    }
    60% {
        -moz-transform: perspective(550px) rotateX(15deg);
        opacity: .6;
    }
    80% {
        -moz-transform: perspective(550px) rotateX(-7deg);
        opacity: .8;
    }
    100% {
        -moz-transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

@-o-keyframes yo-flip {
    0% {
        -o-transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    30% {
        -o-transform: perspective(550px) rotateX(-35deg);
        opacity: .4;
    }
    60% {
        -o-transform: perspective(550px) rotateX(15deg);
        opacity: .6;
    }
    80% {
        -o-transform: perspective(550px) rotateX(-7deg);
        opacity: .8;
    }
    100% {
        -o-transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

@keyframes yo-flip {
    0% {
        transform: perspective(550px) rotateX(90deg);
        opacity: 0;
    }
    30% {
        transform: perspective(550px) rotateX(-35deg);
        opacity: .4;
    }
    60% {
        transform: perspective(550px) rotateX(15deg);
        opacity: .6;
    }
    80% {
        transform: perspective(550px) rotateX(-7deg);
        opacity: .8;
    }
    100% {
        transform: perspective(550px) rotateX(0deg);
        opacity: 1;
    }
}

